import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import Button from "./components/Button";
//import Input from "./components/Input";
import getWeb3 from "./getWeb3";
import abi from "./abi.json";
import data from "./imagrData.json";
import { initializeApp } from "firebase/app";
import { ReactComponent as Logo } from "./logo.svg";
import { getDatabase, ref, set, get } from "firebase/database";

function App() {
  const [tokenId, setTokenId] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [gift, setGift] = useState("");

  const abiContractRef = useRef(null);
  const web3 = useRef(null);
  const [currentAccount, setCurrentAccount] = useState("");

  var errorstate = false;

  const appContractAddress = "0xcBC2E38f7b91592A3976CB9E7303062Be8266D7E";
  //const appContractAddress = "0x10B46C6cC00BddAd4B52ea8ef579F06ECe23112B";
  const marketUrlPrefix = "https://opensea.io/assets/ethereum/";

  const firebaseConfig = {
    
    authDomain: "gift-bb304.firebaseapp.com",
    databaseURL: "https://gift-bb304-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "gift-bb304",
    storageBucket: "gift-bb304.appspot.com",
    messagingSenderId: "896397477435",
    appId: "1:896397477435:web:aff9ed09dc2049b9247e1e",
    measurementId: "G-15QRG3MH5Y"
  };
  firebaseConfig.apiKey = "AIzaSyCRrC9sYB-Y4koWY-W8R64bXK9gaVCc1NE";


  const app = initializeApp(firebaseConfig);

  useEffect(() => {
    async function fetchData() {
      try {
        const web = await getWeb3();
        web3.current = web;
        setCurrentAccount(await web.eth.currentProvider.selectedAddress);
        const abiContract = new web.eth.Contract(
          abi["abi"],
          appContractAddress
        );
        abiContractRef.current = abiContract;
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const checkOwnership = async () => {
    try{
      const owner = await abiContractRef.current.methods
        .ownerOf(tokenId)
        .call({ from: currentAccount });
        console.log(owner);
      return owner.toLowerCase() === currentAccount.toLowerCase();
    } catch (error) {
      errorstate = true;
      console.error(error);
      alert ("Something went wrong on the Blockchain. Please use a desktop computer, check your tokenID, wallet and network.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const db = getDatabase(app);
    const concat = currentAccount + "-" + tokenId;
    const dbRef = ref(db, "users/" + concat);
    //const dbRef = ref(db, "users/" + currentAccount);
    const snapshot = await get(dbRef);

    // check if one of the gifts is selected
    // check if the gift hasn't been claimed yet
    // check ownership

    if (snapshot.exists()) {
      // check if the gift property exists in the snapshot
      console.log(snapshot.val());
      console.log(snapshot.val().tokenId,"-",String(tokenId));
      if (snapshot.val().tokenId === String(tokenId)){
      //if (snapshot.hasChild("gift").val()) {
        errorstate = true;
        alert("Sorry gift already claimed for this NFT!");
     // }
    }
    }

    if (!gift) {
      errorstate = true;
      alert ("choose a gift");
    }
    
    if (await checkOwnership() === false) {
      errorstate = true;
      alert("You are not the owner."); 
    } 

    
    if (!errorstate){
      if (await checkOwnership()){
        const marketUrl = marketUrlPrefix + appContractAddress + "/" + tokenId;
        const response = {
          name,
          email,
          phone,
          address,
          currentAccount,
          tokenId,
          zipcode,
          city,
          state,
          country,
          gift,
          marketUrl
        };
        set(ref(db, "users/" + concat), response);
        alert("transaction succesful")
        window.location.reload();
      }
    }
    
 
  };

  return (
    <>
      <div className="App">
        <Logo alt="Logo" />
        <div className="container">
          <div class="row">
            <div className="left-section col">
              <h2 className="mb-3">Choose a Gift</h2>
              <label className="ms-2">
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="gift"
                      value="Tile"
                      checked={gift === "Tile"}
                      onChange={(event) => setGift(event.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>
                      <strong>Tile</strong>
                    </span>
                    <img src={data["tile"]} alt="tile" className="m-3" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>15x15cm Ceramic tile &nbsp;&nbsp;&nbsp;</p>
                    <p>0$+Shipping and handling</p>
                  </div>
                </div>
              </label>

              <label className="ms-2">
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="gift"
                      value="Print"
                      checked={gift === "Print"}
                      onChange={(event) => setGift(event.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>
                      <strong>Print</strong>
                    </span>
                    <img src={data["print"]} alt="print" className="m-3" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>40x30cm including whitespace &nbsp;&nbsp;&nbsp;</p>
                    <p>0$+Shipping and handling</p>
                  </div>
                </div>
              </label>

              <label className="ms-2">
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="gift"
                      value="Both"
                      checked={gift === "Both"}
                      onChange={(event) => setGift(event.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>
                      <strong>Both</strong>
                    </span>
                    <img src={data["print"]} alt="print" className="m-3" />
                    <img src={data["tile"]} alt="tile" />
                  </div>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    35$+Shipping and handling
                  </p>
                </div>
              </label>

              <br />
              <br />
              <p>
                Please only use desktop for this app. If you have multiple tokens you have to claim each NFT separately through the form. 
                Should you encounter errors please reload page first and try again. Should the issue
                persist. Contact us via support@artattainment.com. After claiming a gift please don't sell or transfer
                your NFT till after you have received your shipment. We will check if NFT is still in your posession. 
                We will void your order without refund if you transfered after claiming but before receiving the gift.
              </p>
              <p>
                You have till the end of June to claim your gift. You will receive e-mail from us when the claim period is over. This will be after the 1st of July 
                but before the 7th of July. If you don't hear from us on or before the 7th of July please
                contact us via support@artattainment.com so we can check what went wrong.
              </p>
              <p>
                The mail will contain payment details and the total cost of shipping and handling based
                on your address and number of tokens and types of gifts chosen. Make sure you provide
                correct address, email address and phone number (in international format numbers only starting with a +). Estimate for shipping and handling of one
                item within Europe is 35 USD. Items will be shipped from Europe. You may have to pay for
                import duties depending on your countries rules and regulations.
              </p>
              <p>
                After the 30th of June you can no longer claim any gifts. The remaining NFTs will only stay digital. 
                We will then update all metadata and start processing all claims individually and send out emails.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                You will receive payment information after the claim period via e-mail. Your 
                claim will be void if we don't receive your payment within specified time in the mail.
                We will give everyone a reasonable amount of time to pay. Once the payment period is over
                we will supply the complete list of orders to the organization handling the processing 
                and start shipping out all items.
              </p>
              <p>
                Thank you for your patience and support!
              </p>
            </div>
            <div className="right-section col">
              <h1>Form Submission</h1>
              <form onSubmit={handleSubmit}>
                <label className="mt-3">
                  {" "}
                  <strong>Token ID:</strong>{" "}
                  <input
                    required
                    type="number"
                    value={tokenId}
                    onChange={(event) => setTokenId(event.target.value)}
                  />
                </label>
                <label className="mt-3">
                  {" "}
                  <strong>Name:</strong>{" "}
                  <input
                    required
                    type="text"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </label>
                <label className="mt-3">
                  {" "}
                  <strong>Email:</strong>{" "}
                  <input
                    required
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </label>
                <label className="mt-3">
                  {" "}
                  <strong>Phone Number:</strong>{" "}
                  <input
                    required
                    type="phone"
                    pattern="^\+\d{1,15}$"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </label>
                <label className="mt-3">
                  {" "}
                  <strong>Address:</strong>{" "}
                  <input
                    required
                    type="text"
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </label>
                <label className="mt-3">
                  {" "}
                  <strong>Zipcode:</strong>{" "}
                  <input
                    required
                    type="text"
                    value={zipcode}
                    onChange={(event) => setZipcode(event.target.value)}
                  />
                </label>
                <label className="mt-3">
                  {" "}
                  <strong>City:</strong>{" "}
                  <input
                    required
                    type="text"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                  />
                </label>
                <label className="mt-3">
                  {" "}
                  <strong>State:</strong>{" "}
                  <input
                    required
                    type="text"
                    value={state}
                    onChange={(event) => setState(event.target.value)}
                  />
                </label>
                <label className="mt-3">
                  {" "}
                  <strong>Country:</strong>{" "}
                  <input
                    required
                    type="text"
                    value={country}
                    onChange={(event) => setCountry(event.target.value)}
                  />
                </label>
                <Button type="submit">Validate & Claim</Button>
              </form>
              <br />
              <p>
                We value your privacy. By claiming your gift you give us permission to share the information
                you have supplied with the people and organizations handling the processing of the gift claim.
              </p>

            </div>
            
          </div>
        </div>
        <div className="mt-4">
          <h3 className="md-4">Art Attainment Team</h3>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="m-2">
              <a
                href="https://twitter.com/dbnw_nft"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={data["twitter"]}
                  alt="Twitter"
                  style={{ width: "50px", height: "50px" }}
                />
              </a>
            </div>
            <div className="m-2">
              <a
                href="https://opensea.io/collection/the-delft-blue-night-watch"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={data["opensea"]}
                  alt="Opensea"
                  style={{ width: "50px", height: "50px" }}
                />
              </a>
            </div>
            <div className="m-2">
              <a
                href="https://delftbluenightwatch.com/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={data["site"]}
                  alt="Delft Blue Night Watch"
                  style={{ width: "50px", height: "50px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
